<template>
  <div id="productSpecsForm" class="form">
    <v-form v-if="loaded" ref="form" v-model="valid" lazy-validation>
      <product-select v-model="productSpecs.productId" />

      <div class="d-flex justify-space-between" v-for=" (e , index) in productSpecs.elements" :key="e.id">
        <div style="min-width: 100px" class="my-auto mx-5">{{ e.element.elementName }} ({{e.element.symbol}})</div>
        <v-text-field class="mx-5" clearable v-model="e.minPercent" label="Min" placeholder="Leave blank for no limit"/>
        <v-text-field class="mx-5" clearable v-model="e.maxPercent" label="Max" placeholder="Leave blank for no limit"/>
        <v-btn @click="remove(e, index)" icon class="my-auto mx-5">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </div>

      <div class="d-flex flex-row mt-4 mx-5">
        <v-select
          v-model="newElement"
          :items="productSpecs.relations.elements"
          label="Elements"
          :multiple="false"
          item-text="label"
          item-value="id"
          clearable
          return-object
        ></v-select>
        <v-btn class="mt-3 ml-5" @click="addElement">Add</v-btn>
      </div>
      <div class="mt-4 d-flex justify-space-between">
        <v-btn @click="goBack"> Back </v-btn>
        <v-btn @click="handleSubmit" color="primary">
          {{ id ? "Update" : "Save" }}
        </v-btn>
        <span></span>
      </div>
    </v-form>
  </div>
</template>

<script>
import { getProductSpecs } from "@/services/productSpecs";
const ProductSelect = () => import("@/components/product/ProductSelect.vue");
import { getAllLabElements } from "@/services/labElement";
import { deleteProductSpecsElement } from "@/services/productSpecsElement";

import { mapActions } from "vuex";

export default {
  props: ["id", "productId"],
  data() {
    return {
      loaded: false,
      valid: false,
      newElement: null,
      productSpecs: {
        relations: {
          elements: [],
        },
        productId: "",
        elements: [],
      },
    };
  },
  created() {
    this.setInstance();

    console.log(this.productSpecs)
  },
  methods: {
    ...mapActions("productSpecs", ["createProductSpecs", "updateProductSpecs"]),
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),

    async handleSubmit() {
      if (this.id) {
        let productSpecs = this.productSpecs;
        delete productSpecs.relations;

        this.updateProductSpecs(productSpecs)
          .then((response) => {
            console.log(response);
            this.addSuccessMessage("ProductSpecs was updated successfully");
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error updating ProductSpecs, please try again later"
            );
          });
      } else {
        let productSpecs = this.productSpecs;
        delete productSpecs.relations;

        this.createProductSpecs(productSpecs)
          .then((response) => {
            console.log(response);
            this.addSuccessMessage("ProductSpecs was created successfully");
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error creating ProductSpecs, please try again later"
            );
          });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    setInstance() {
      if (this.productId) {
        this.productSpecs.productId = this.productId;
      }
      if (this.id) {
        this.productSpecs.id = this.id;
        getProductSpecs(this.id).then((response) => {
          let instance = response.data;
          for (let property in instance) {
            if (
              Object.prototype.hasOwnProperty.call(instance, property) &&
              Object.prototype.hasOwnProperty.call(this.productSpecs, property)
            ) {
              this.productSpecs[property] = instance[property];
            }
          }
          this.loaded = true;
        });
      } else {
        this.loaded = true;
      }
      this.setDependencies();
    },
    setDependencies() {
      const params_elements = {
        page: 0,
        size: 400,
        sort: 'symbol',
      };
      getAllLabElements(params_elements).then((response) => {
        const elements = response.data.content;
        elements.forEach((element) => {
          element.label = `${element.elementName} (${element.symbol})`
        })
        this.productSpecs.relations.elements = elements;
      });
    },

    addElement(){
      console.log('adding new element: ', this.newElement)
    
      this.productSpecs.elements.push({labElementId: this.newElement.id, element: this.newElement, minPercent: null, maxPercent: null, productSpecsId: this.productSpecs.id})
    },
    remove(e, index){
      if(e.id){
        deleteProductSpecsElement(e.id)
      }
      this.productSpecs.elements.splice( index, 1)
    }
  },
  components: {
    ProductSelect,
  },
};
</script>
